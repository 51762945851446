// extracted by mini-css-extract-plugin
export var recentPost = "bN_mF";
export var recentPost__button = "bN_mW";
export var recentPost__postAuthor = "bN_mQ";
export var recentPost__postAuthorImage = "bN_mP";
export var recentPost__postAuthorName = "bN_mR";
export var recentPost__postCard = "bN_mG";
export var recentPost__postCategory = "bN_mK";
export var recentPost__postDate = "bN_mV";
export var recentPost__postDescriprion = "bN_mM";
export var recentPost__postImage = "bN_mJ";
export var recentPost__postInfo = "bN_mS";
export var recentPost__postInfoWrapper = "bN_mN";
export var recentPost__postLink = "bN_mH";
export var recentPost__postStats = "bN_mT";
export var recentPost__postTitle = "bN_mL";
export var recentPost__title = "bN_mX";