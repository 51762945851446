// extracted by mini-css-extract-plugin
export var socialBlock = "cY_wQ";
export var socialBlock__icon = "cY_wW";
export var socialBlock__iconWrapper = "cY_wV";
export var socialBlock__icon_facebook = "cY_wX";
export var socialBlock__icon_instagram = "cY_w0";
export var socialBlock__icon_linkedin = "cY_wY";
export var socialBlock__icon_telegram = "cY_w1";
export var socialBlock__icon_tiktok = "cY_w2";
export var socialBlock__icon_twitter = "cY_wZ";
export var socialBlock__iconsWrapper = "cY_wT";
export var socialBlock__sprite = "cY_wS";
export var socialBlock__title = "cY_wR";